import { AuthControl } from "../authControl";
import { Roadtrip } from "../models/roadtrip";
import { Stop } from "../models/stop";
import { Ref, ref } from "vue";
import { ResponseJSON } from "./serviceUtils";
import { Service, ServiceImpl } from "./service";
import { CreateStopParams, hydrateStop } from "./stopsService";

export type GetRoadtripsResult = {
  roadtrips: Array<Roadtrip>,
  isMore: boolean
};

export type CreateRoadtripParams = {
  origin: CreateStopParams;
  destination: CreateStopParams;
  roundtrip?: boolean 
};


export interface RoadtripsService extends Service {
  populateRoadtrips(): Promise<void>;
  populateRoadtrip(roadtripId: string): Promise<void>;

  createRoadtrip(roadtripParams: CreateRoadtripParams): Promise<Roadtrip>;
  getRoadtrips(limit?: number): Promise<GetRoadtripsResult>;
  getRoadtrip(roadtripId: string): Promise<Roadtrip>;
  restoreRoadtrip(roadtrip: Roadtrip): Promise<any>;
  addStopToRoadtrip(roadtrip: Roadtrip, stop: Stop): Promise<Roadtrip>;
  updateRoadtripStops(roadtrip: Roadtrip, stops: Array<Stop>): Promise<Roadtrip>;
  updateRoadtripTitle(roadtrip: Roadtrip, title: string): Promise<Roadtrip>;
  deleteRoadtrip(roadtrip: Roadtrip): Promise<void>;
  deleteRoadtrips(): Promise<void>;
  shareRoadtrip(roadtrip: Roadtrip, email: string): Promise<Roadtrip>;

  /**
   * @deprecated - use the composable (useRoadtrips) to access 'roadtrips')
   */
  roadtrips: Ref<Array<Roadtrip>>;
  /**
   * @deprecated - use the composable (useRoadtrips) to access 'deletedRoadtrips')
   */
  deletedRoadtrips: Ref<Array<Roadtrip>>;
  /**
   * @deprecated - use the composable (useRoadtrip) to access 'roadtrip')
   */
  roadtrip: Ref<Roadtrip | undefined>;
}

type roadtripResponseJSON = ResponseJSON & Roadtrip;
type RoadtripsResponseJSON = ResponseJSON & { roadtrips: Array<Roadtrip> };

export function hydrateRoadtrip(roadtrip: Roadtrip) {

  const stops = (roadtrip.stops || []);
  stops.forEach(hydrateStop);
  if (roadtrip.origin) hydrateStop(roadtrip.origin, 0);
  if (roadtrip.destination) hydrateStop(roadtrip.destination, stops.length - 1);
  
  // If we have a title for a road trip, use it. Otherwise - make one up.
  if (!roadtrip.title) {
    if (roadtrip.origin
      && roadtrip.origin.title
      && roadtrip.destination
      && roadtrip.destination.title) {
      roadtrip.title = `${roadtrip.origin?.title} to ${roadtrip.destination?.title}`;
    } else {
      roadtrip.title = 'Your Epic Road Trip!'
    }
  }

  roadtrip.navigationUrl = buildNavigationUrl(roadtrip);
};

export function roadtripFromResponseJSON(responseJSON: roadtripResponseJSON): Roadtrip {
  delete responseJSON.status;
  const roadtrip: Roadtrip = responseJSON;

  hydrateRoadtrip(roadtrip);

  return roadtrip;
}

// TODO(tjohns): Get this from the backend, now that we build them there for sharing
function buildNavigationUrl(roadtrip: Roadtrip) {
  let waypoints:Array<string> = [];
  let waypointPlaceIds:Array<string> = [];

  if (roadtrip.origin
    && roadtrip.origin.title
    && roadtrip.origin.googlePlaceId
    && roadtrip.destination
    && roadtrip.destination.title
    && roadtrip.destination.googlePlaceId) {

    if (roadtrip.stops && roadtrip.stops.length > 2) {
      const stops = roadtrip.stops
        .slice(1, -1) // remove origin and destination
        .filter(stop => stop.googlePlaceId != undefined) // remove any without Google placeIds

      waypoints = stops
        .map((stop) => { return stop.title || 'Roadtrip Stop' })

      waypointPlaceIds = stops
        .map((stop) => { return stop.googlePlaceId! })
    }

    const waypointParams = (waypoints.length > 0) ? `&waypoints=${waypoints.map(encodeURIComponent).join('%7C')}&waypoint_place_ids=${waypointPlaceIds.map(encodeURIComponent).join('%7C')}` : '';
    
    return `https://www.google.com/maps/dir/?api=1`
      + `&dir_action=navigate`
      + `&origin=${encodeURIComponent(roadtrip.origin.title)}`
      + `&origin_place_id=${encodeURIComponent(roadtrip.origin.googlePlaceId)}`
      +  waypointParams
      + `&destination=${encodeURIComponent(roadtrip.destination.title)}`
      + `&destination_place_id=${encodeURIComponent(roadtrip.destination.googlePlaceId)}`
      + `&travelmode=driving`    
  }
};


export class RoadtripsServiceImpl extends ServiceImpl implements RoadtripsService {
  /**
   * @deprecated - use the composable (useRoadtripsService = {...}) to access 'roadtrips')
   */
  public readonly roadtrips = ref<Array<Roadtrip>>([]);
  /**
   * @deprecated - use the composable (useRoadtripsService = {...}) to access 'deletedRoadtrips')
   */
  public readonly roadtrip = ref<Roadtrip>();
  /**
   * @deprecated - use the composable (useRoadtripsService = {...}) to access 'roadtrip')
   */
  public readonly deletedRoadtrips = ref<Array<Roadtrip>>([]);

  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {

    authControl.onAuthStateChanged((isSignedIn) => { 

      if (isSignedIn) {
        this.populateRoadtrips();
      } else {
        this.roadtrip.value = undefined;
        this.roadtrips.value = [];
        this.deletedRoadtrips.value = [];
      }
    });

    authControl.onDataMigrationComplete(async () => {
      return this.populateRoadtrips();
    })

    super(apiServer, authControl)
  };

  public async populateRoadtrips(): Promise<void> {

    this.deletedRoadtrips.value = [];
    this.roadtrips.value = [];
    const result = await this.getRoadtrips();
    const roadtrips = result.roadtrips.sort((a, b) => { return new Date(b.creationTimestamp).getTime() - new Date(a.creationTimestamp).getTime() })
    this.deletedRoadtrips.value = roadtrips.filter((roadtrip) => !!roadtrip.deletionTimestamp);
    this.roadtrips.value = roadtrips.filter((roadtrip) => !roadtrip.deletionTimestamp);
  }

  public async populateRoadtrip(roadtripId: string): Promise<void> {
    this.loading.value++;
    this.roadtrip.value = await this.getRoadtrip(roadtripId);
    this.loading.value--;
  }

  async createRoadtrip(roadtripParams: CreateRoadtripParams): Promise<Roadtrip> {
    return this.post(
      '/v1/roadtrips', 
      roadtripParams,
      roadtripFromResponseJSON
    )
  }

  async getRoadtrips(limit?: number): Promise<GetRoadtripsResult> {
    const queryParams: Array<string> = [];
    if (limit) {
      queryParams.push(`limit=${limit + 1}`);
    }

    const url = `/v1/roadtrips` +
      (queryParams.length ? ("?" + queryParams.join('&')) : "");

    return await this.get(url, (responseJSON: RoadtripsResponseJSON) => {

      const result: GetRoadtripsResult = {
        roadtrips: [],
        isMore: false
      }
  
      const roadtripsFetched = responseJSON.roadtrips || [];

      result.roadtrips = roadtripsFetched.slice(0, limit);
      result.isMore = limit ? (roadtripsFetched.length > limit) : false;

      result.roadtrips.forEach(hydrateRoadtrip);

      return result;
    })
  }

  async getRoadtrip(roadtripId: string): Promise<Roadtrip> {
    return this.get(`/v1/roadtrips/${roadtripId}`, roadtripFromResponseJSON);
  }

  async deleteRoadtrip(roadtrip: Roadtrip) :Promise<void> {
    return this.delete(`/v1/roadtrips/${roadtrip.roadtripId}`);
  }
  
  async restoreRoadtrip(roadtrip: Roadtrip) :Promise<any> {
    return this.patch(`/v1/roadtrips/${roadtrip.roadtripId}`, {deletionTimestamp: null}, roadtripFromResponseJSON)
  }

  async updateRoadtripTitle(roadtrip: Roadtrip, title: string): Promise<Roadtrip> {

    this.loading.value++;

    const updatedRoadtrip = await this.patch(
      `/v1/roadtrips/${roadtrip.roadtripId}`, 
      { title }, 
      roadtripFromResponseJSON
    );

    this.roadtrip.value = updatedRoadtrip;

    this.loading.value--;

    return updatedRoadtrip;

  }

  async updateRoadtripStops(roadtrip: Roadtrip, stops: Array<Stop>): Promise<Roadtrip> {

    this.loading.value++;

    const updatedRoadtrip = await this.patch(
      `/v1/roadtrips/${roadtrip.roadtripId}`, 
      { stops: stops.map((stop) => { return stop.stopId; }) }, 
      roadtripFromResponseJSON
    );

    this.roadtrip.value = updatedRoadtrip;

    this.loading.value--;

    return updatedRoadtrip;

  }

  async addStopToRoadtrip(roadtrip: Roadtrip, stop: Stop): Promise<Roadtrip> {

    const stops = roadtrip.stops || [];
    // Add it right after the origin (first) stop, if any
    stops.splice(1, 0, stop);

    return this.updateRoadtripStops(roadtrip, stops);

  }
 
  async deleteRoadtrips(): Promise<void> {
    return this.delete('/v1/roadtrips')
  }

  async shareRoadtrip(roadtrip: Roadtrip, email: string): Promise<Roadtrip> {

    this.loading.value++;

    const updatedRoadtrip = await this.patch(
      `/v1/roadtrips/${roadtrip.roadtripId}`, 
      { sharedWith: [email] }, 
      roadtripFromResponseJSON
    );

    this.roadtrip.value = updatedRoadtrip;

    this.loading.value--;

    return updatedRoadtrip;
  }
};