import { Stop } from "../models/stop";
import { AuthControl } from "../authControl"
import { ResponseJSON } from "./serviceUtils";
import { Service, ServiceImpl } from "./service";
import { Ref, ref } from "vue";

export type CreateStopParams = {
  userProvidedName: string,
  title?: string,
  navigationName?: string,
  descriptionName?: string,
  googlePlaceId?: string,
  googlePlaceTypes?: Array<string>,
  latLng?: {lat: number, lng: number},
  timezone?: string,
  arrivalDateTime?: string,
  departureDateTime?: string,
  stopTemplateId?: string
};

export type UpdateStopDatesParams = {
  arrivalDateTime?: string,
  departureDateTime?: string,
};

export interface StopsService extends Service {
  populateStop(stopId: string): Promise<void>;

  createStop(createStopParams: CreateStopParams): Promise<Stop>;
  getStop(stopId: string): Promise<Stop>;
  updateStopDates(stopId: string, dates: UpdateStopDatesParams): Promise<Stop>;

  stop: Ref<Stop | undefined>;
}

export function hydrateStop(stop: Stop, index?: number): Stop {

  function titleForStop(stop: Stop, index?: number) {
    const lastResort = index ? `Stop #${index + 1}` : "Stop";
  
    // If we have a title for a stop, use it. Otherwise - make one up.
    return stop.title 
      || stop.descriptionName
      || stop.navigationName
      || stop.userProvidedName
      || lastResort;
  }

  stop.title = titleForStop(stop)

    // TODO(tjohns): Consider hydrating other synthetic values that
    // we may not have stored on the server at some point, such as:
    //
    // latLng - 
    // timezone - depends on the time AND location...
    // navigationUrl
    // photoUrl
    // photoAttributions
    //

    return stop;

}


type StopsResponseJSON = ResponseJSON & Stop;

function stopFromResponseJSON(responseJSON: StopsResponseJSON): Stop {
  delete responseJSON.status;
  const stop: Stop = responseJSON;

  hydrateStop(stop);

  return stop
}

export class StopsServiceImpl extends ServiceImpl implements StopsService {
  public readonly stop = ref<Stop>();

  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl);

    authControl.onAuthStateChanged((isSignedIn) => { 

      if (!isSignedIn) {
        this.stop.value = undefined;
      }
    });

  };

  public async populateStop(stopId: string): Promise<void> {
    this.loading.value++;
    this.stop.value = await this.getStop(stopId);
    this.loading.value--;
  }

  async createStop(createStopParams: CreateStopParams): Promise<Stop> {
    return this.post(
        `/v1/stops`, 
        createStopParams, 
        stopFromResponseJSON
    );
  }

  async getStop(stopId: string): Promise<Stop> {
    return this.get(`/v1/stops/${stopId}`, stopFromResponseJSON);
  }

  async updateStopDates(stopId: string, dates: UpdateStopDatesParams): Promise<Stop> {
    this.loading.value++;
    const updatedStop = await this.patch(`/v1/stops/${stopId}`, dates, stopFromResponseJSON);
    this.stop.value = updatedStop;
    this.loading.value--;
    return updatedStop;
  }

}
